<template lang="">
  <div>
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center justify-space-between"
      >
        <span class="titulo-pagina">
          {{ tituloFormulario }}
        </span>
      </v-col>
    </v-row>
    <v-form
      ref="form"
      class="row"
    >
      <input-date
        v-if="ehPorReagendamento"
        v-model="form.dataAgendamento"
        class="col-12"
        :label="
          $t(
            'modulos.roteiro_execucao_envio_material.reagendamentoOuCancelamento.dataAgendamento'
          )
        "
        obrigatorio
        :allowed-days="diasEnvio"
        apenas-datas-futuras-sem-hoje
      />
      <input-select
        v-else
        v-model="form.motivoCancelamento"
        :label="
          $t(
            'modulos.roteiro_execucao_envio_material.reagendamentoOuCancelamento.motivoCancelamento'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        :input-adicionar="true"
        obrigatorio
        com-botao-adicionar
        class="col-12"
        :options="opcoes.motivoCancelamento"
        retornar-objeto
      />
      <input-select
        v-model="form.contatoAtendimento"
        :label="
          $t(
            'modulos.roteiro_execucao_envio_material.reagendamentoOuCancelamento.contato'
          )
        "
        :placeholder="$t('geral.inputs.selecione')"
        :input-adicionar="true"
        obrigatorio
        com-botao-adicionar
        class="col-12"
        :options="opcoes.contato"
        retornar-objeto
      >
        <template #botaoAdicionar>
          <botao-padrao
            :botao-adicionar-input="true"
            @click="abrirCadastroParticipante"
          >
            <span style="font-size: 23px"> + </span>
          </botao-padrao>
        </template>
      </input-select>
      <input-textarea
        v-model="form.observacao"
        obrigatorio
        class="col-12"
        :label="
          $t(
            'modulos.roteiro_execucao_envio_material.reagendamentoOuCancelamento.observacao'
          )
        "
      />
    </v-form>
    <div class="d-flex">
      <div class="d-flex flex-column align-center justify-center mr-2 mt-4">
        <div class="circulo-checkin" />
        <div class="divider-checkin">
          <v-divider
            vertical
            inset
          />
        </div>
        <div class="circulo-checkin vazio-checkin" />
      </div>
      <div class="informacao-checkin flex-grow-1 mt-6">
        <div>
          <span class="primary-grey-500--text">{{
            $t(
              'modulos.roteiro_execucao_envio_material.reagendamentoOuCancelamento.envioMaterial'
            )
          }}</span>
          <span>{{ clienteAtualDeTotalClientes }}</span>
        </div>
        <div>
          <span class="primary-grey-500--text">{{
            $t(
              'modulos.roteiro_execucao_envio_material.reagendamentoOuCancelamento.cliente'
            )
          }}</span>
          <span>{{ clienteNome }}</span>
        </div>
        <div>
          <span class="primary-grey-500--text">{{
            $t(
              'modulos.roteiro_execucao_envio_material.reagendamentoOuCancelamento.endereco'
            )
          }}</span>
          <a
            :href="`${googleMaps}/${endereco}`"
            target="_blank"
            class="text-decoration-underline primary-blue-500--text"
          >{{ endereco }}</a>
        </div>
        <div>
          <span class="primary-grey-500--text">{{
            $t(
              'modulos.roteiro_execucao_envio_material.reagendamentoOuCancelamento.codigo'
            )
          }}</span>
          <span>{{ codigo }}</span>
        </div>
        <div>
          <span class="primary-grey-500--text">{{
            $t(
              'modulos.roteiro_execucao_envio_material.reagendamentoOuCancelamento.dataSolicitacao'
            )
          }}</span>
          <span>{{ dataSolicitacao }}</span>
        </div>
        <div>
          <span class="primary-grey-500--text">{{
            $t(
              'modulos.roteiro_execucao_envio_material.reagendamentoOuCancelamento.volume'
            )
          }}</span>
          <span>{{ volume }}</span>
        </div>
      </div>
    </div>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-center justify-md-end">
      <botao-padrao
        outlined
        color="secondary"
        class="mr-2 flex flex-grow-1 flex-md-grow-0"
        btn100
        @click="cancelar"
      >
        <v-icon class="mr-1">
          $mdiCancel
        </v-icon>
        {{ $t('geral.botoes.cancelar') }}
      </botao-padrao>

      <botao-padrao
        class="flex-grow-1 flex-md-grow-0"
        btn100
        @click="checkout"
      >
        <v-icon class="mr-1">
          $mdiContentSaveOutline
        </v-icon>
        {{ $t('geral.botoes.salvar') }}
      </botao-padrao>
    </div>
    <contato-modal
      ref="contato-modal"
      :participante-id="clienteDaVez.id"
      @atualizar-contato="atualizarContato"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { DropdownModel } from '@/common/models/geral/DropdownModel';
import {
  ParticipanteService,
  RoteiroMotivoCancelamentoService,
  RoteiroEnvioMaterialService,
} from '@/common/services/cadastros'; //RoteiroEnvioMaterialService,
import ContatoModal from './modal/ContatoParticiapante';
import helpers from '../../../../common/utils/helpers';
import { sortBy } from 'lodash';
import moment from 'moment';
import { RoteiroExecutarEntregaRecebimentoModel } from '../../../../common/models/cadastros/RoteiroExecutarEntregaRecebimentoModel';

export default {
  components: {
    ContatoModal,
  },
  data() {
    return {
      form: new RoteiroExecutarEntregaRecebimentoModel({}),
      googleMaps: 'https://www.google.com.br/maps/place',
      diasEnvio: [],
      endereco: '',
      clienteNome: '',
      dataSolicitacao: '',
      codigo: '',
      volume: 0,
      opcoes: {
        contato: [],
        motivoCancelamento: [],
      },
      naoPodeDiaHoje: moment().add(1, 'days').format('YYYY-MM-DD'),
    };
  },
  computed: {
    ...mapGetters('Roteiro', [
      'clienteDaVez',
      'clienteDaVezIndex',
      'envioMaterialDaVez',
      'roteiroId',
      'totalClientes',
    ]),
    tituloFormulario() {
      const { name } = this.$route;
      if (name === 'roteiro-executar-envio-material-checkout-por-reagendamento')
        return this.$t(
          'modulos.roteiro_execucao_envio_material.titulos.reagendamento'
        );
      return this.$t(
        'modulos.roteiro_execucao_envio_material.titulos.cancelamento'
      );
    },
    clienteAtualDeTotalClientes() {
      return `${this.clienteDaVezIndex + 1}/${this.totalClientes}`;
    },
    ehPorReagendamento() {
      const { name } = this.$route;
      return (
        name === 'roteiro-executar-envio-material-checkout-por-reagendamento'
      );
    },
  },
  async mounted() {
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
    await this.buscarMotivoCancelamento();
    if (this.ehPorReagendamento) {
      await this.buscarDiasEnvioParticipante(this.clienteDaVez.id);
    }
    // console.log(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10))

    const {
      nome,
      endereco: {
        cep,
        logradouro,
        numero,
        bairro,
        unidadeFederativa: { nome: uf },
        municipio: { nome: cidade },
      },
    } = this.clienteDaVez;

    this.endereco = `${logradouro}, ${numero}, ${bairro}, ${cidade} - ${uf}, ${cep}`;
    this.clienteNome = nome;
    this.dataSolicitacao = helpers.formatarDataBr(
      this.envioMaterialDaVez.dataSolicitacao
    );
    this.codigo = this.envioMaterialDaVez.codigo;
    this.opcoes.contato = new DropdownModel(this.clienteDaVez.contatos);
    this.form.contatoAtendimento = this.opcoes.contato.at(0);
  },
  methods: {
    abrirCadastroParticipante: function () {
      this.$refs['contato-modal'].abrirModal();
    },
    atualizarContato: function () {
      ParticipanteService.buscar(this.clienteDaVez.id).then((res) => {
        this.opcoes.contato = res.data.contatos.map(
          (contato) => new DropdownModel(contato)
        );
        this.form.contatoAtendimento = this.opcoes.contato.at(-1);
      });
    },
    buscarMotivoCancelamento: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await RoteiroMotivoCancelamentoService.listar()
        .then((res) => {
          this.opcoes.motivoCancelamento = new DropdownModel(res.data.items);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'roteiro-executar-envio-material-checkin' });
      });
    },
    buscarDiasEnvioParticipante: async function (id) {
      this.diasEnvio = [];
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParticipanteService.buscar(id)
        .then((res) => {
          if (res.data?.flagEnvioMaterialSegundaFeira == true)
            this.diasEnvio.push(0);
          if (res.data?.flagEnvioMaterialTercaFeira == true)
            this.diasEnvio.push(1);
          if (res.data?.flagEnvioMaterialQuartaFeira == true)
            this.diasEnvio.push(2);
          if (res.data?.flagEnvioMaterialQuintaFeira == true)
            this.diasEnvio.push(3);
          if (res.data?.flagEnvioMaterialSextaFeira == true)
            this.diasEnvio.push(4);

          if (this.diasEnvio.length == 0) {
            this.toastErro(
              this.$t('modulos.envio_material.erros.cliente_sem_dias_envio')
            );
            this.diasEnvio = null;
            this.form.dataAgendamento = null;
            this.$refs.form.resetValidation();
            return;
          }

          this.buscarDataMaisProxima(this.diasEnvio);
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarDataMaisProxima(dias) {
      const hoje = moment();
      let datasCaluladas = [];

      dias.forEach((dia) => {
        const proximoDataDisponivel = hoje.clone().day(dia + 1);
        if (proximoDataDisponivel.isBefore(hoje))
          proximoDataDisponivel.add(1, 'week');

        datasCaluladas.push(proximoDataDisponivel);
      });
      let data = sortBy(datasCaluladas, (data) => data.diff(hoje));
      if (data[0].isSame(hoje, 'day')) {
        data = data[1];
      } else {
        data = data[0];
      }
      this.form.dataAgendamento = data.format('YYYY-MM-DD');
    },

    checkout: function () {
      const valido = this.$refs.form.validate();
      if (!valido) return;
      this.form.tipoCheckout = this.ehPorReagendamento
        ? 'Reagendamento'
        : 'Cancelamento';
      RoteiroEnvioMaterialService.checkOutCliente(
        this.roteiroId,
        this.envioMaterialDaVez.id,
        this.form.request
      ).then(() => {
        this.$router.push({ name: 'roteiro-executar-envio-material-checkin' });
      });
    },
  },
};
</script>
<style lang="scss">
.circulo-checkin {
  background: #475467;
  width: 0.875rem;
  height: 0.875rem;
  border: 1.5px solid #475467;
  border-radius: 100%;
}

.vazio-checkin {
  background: none;
  border: 1.5px solid #e4e7ec;
}

.divider-checkin {
  height: 24.1rem;
}

.informacao-checkin {
  display: flex;
  flex-direction: column;
  gap: 20px;

  div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}
</style>
