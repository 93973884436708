import _ from 'lodash';
import { DropdownModel } from '@/common/models/geral/DropdownModel';

export class RoteiroExecutarEntregaRecebimentoModel {
  constructor({
    contatoAtendimento,
    observacao,
    tipoCheckout,
    dataAgendamento,
    motivoCancelamento,
    notaFiscal
  }) {
    if(contatoAtendimento)
      this.contatoAtendimento = new DropdownModel(contatoAtendimento);
    this.observacao = observacao;
    this.tipoCheckout = tipoCheckout;
    this.dataAgendamento = dataAgendamento;
    this.motivoCancelamento = motivoCancelamento
    this.notaFiscal = notaFiscal


  }

  get request() {
    let retorno = _.cloneDeep(this);

    retorno.contatoAtendimentoId = retorno.contatoAtendimento?.value; 

    retorno.motivoCancelamentoId = retorno.motivoCancelamento?.value;

    if(retorno.tipoCheckout === 'Normal'){
      retorno.dataAgendamento = null;
      delete retorno.motivoCancelamentoId
    }

    if(retorno.tipoCheckout === 'Reagendamento'){
      retorno.motivoCancelamentoId = null;
      retorno.dataAgendamento = retorno.dataAgendamento.toString().substring(0,10)
    }
    if(retorno.tipoCheckout === 'Cancelamento'){
      retorno.motivoCancelamentoId = retorno.motivoCancelamento?.value;
      retorno.dataAgendamento = null
    }
  
    return retorno;
  }
}
